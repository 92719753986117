import React, { useState, useEffect, lazy } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { AES, enc } from 'crypto-js';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import PageBuilder from '../../containers/PageBuilder/PageBuilder';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import css from './LandingPage.module.css';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error } = props;

  useEffect(() => {
    const reverse = s => {
      return s
        .split('')
        .reverse()
        .join('');
    };

    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));

    if (params.size == 0)
    {
      return;
    }

    for (let p of params) {
      let test = decodeURIComponent(p);
      if (test.indexOf('iamag')< 0)
      {
        return;
      }
    }


    for (let p of params) {
      let test = decodeURIComponent(p);
      let pairs = test.split('iamag');
      //console.log(pairs);

      let decrypted = AES.decrypt(pairs[0], pairs[1].replace(',', '')); // '14691158');
      let decryptedString = decrypted.toString(enc.Utf8);
      //console.log(decryptedString);
      let pairs1 = decryptedString.split('mentorship');
      let username = pairs1[0]
        .toString(enc.Utf8)
        .substring(3)
        .substring(0, pairs1[0].length - 6);
      let secondPart = pairs1[1];

      //console.log(secondPart);
      let userId = pairs[1].replace(',', '').padEnd(8, 'x');
      let passwordBackup = reverse(pairs[1].replace(',', '')).padEnd(8,'x');

      //let result = await sdk.login({ username, password });

      console.log(secondPart);
      console.log(username);
      //console.log(password);

      sdk
        .login({ username, password: secondPart })
        .then(r => {
          console.log(r);

          window.location.href = '/login';

        })
        .catch(ex => {
          console.log(ex);

          // try with alternate for users created by script
          sdk
            .login({ username, password: passwordBackup })
            .then(r => {
              console.log(r);
              window.location.href = '/login';
            })
            .catch(ex1 => {
              console.log(ex1);
            });

        });

      //console.log(result);

      //let decrypted1 = AES.decrypt(test, '14691158--');
      //console.log(decrypted1.toString(enc.Utf8)); //-> empty

      // post('/api/show-hosts',{}).then(resp => {
      //   setHosts(resp)
      //   setLoading(false)
      // }).catch(e => {
      //   setLoading(false)
      //   console.log(e)
      // })
    }
  }, []);

  return (
    <>
      <PageBuilder
        pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
        inProgress={inProgress}
        error={error}
        fallbackPage={<FallbackPage error={error} />}
      />
    </>
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
